<template>
  <div class="component-wrap">
    <div class="banner"></div>
    <div class="header">
      <h3>{{ $t("practice.answer_practice") }}</h3>
      <div>
        <span @click="jump('record')">{{ $t("practice.answer_record") }}</span>
        <span @click="$refs.musicSetRef.open()">{{
          $t("practice.set_up")
        }}</span>
      </div>
    </div>
    <div class="cards">
      <div class="row">
        <div class="card daily" @click="getHistory('daily', 1)">
          <h5>{{ $t("practice.mode.daily") }}</h5>
          <h6>{{ $t("practice.mode.daily_tip") }}</h6>
        </div>
        <div class="card interest" @click="getHistory('interest', 2)">
          <h5>{{ $t("practice.mode.interest") }}</h5>
          <h6>{{ $t("practice.mode.interest_tip") }}</h6>
        </div>
        <div class="card wrong" @click="jump('wrong')">
          <h5>{{ $t("practice.mode.wrong") }}</h5>
          <h6>{{ $t("practice.mode.wrong_tip") }}</h6>
        </div>
      </div>
      <div class="row">
        <div class="card simulation" @click="getHistory('simulation', 3)">
          <h5>{{ $t("practice.mode.simulation") }}</h5>
          <h6>{{ $t("practice.mode.simulation_tip") }}</h6>
        </div>
        <div class="card self" @click="getHistory('self', 5)">
          <h5>{{ $t("practice.mode.self") }}</h5>
          <h6>{{ $t("practice.mode.self_tip") }}</h6>
        </div>
      </div>
    </div>
    <musicSet ref="musicSetRef" />
    <folderSele ref="folderSeleRef" />
    <labelSele ref="labelSeleRef" />
    <wrongSele ref="wrongSeleRef" />
    <simulationSele ref="simulationSeleRef" />
    <selfSele ref="selfSeleRef" />
  </div>
</template>

<script>
import { reactive, ref, toRefs, getCurrentInstance, createVNode } from "vue";
import { useRouter } from "vue-router";

import ls from "@/utils/local-storage";

import {
  practiceConfig,
  interestLabels,
  practiceHistory,
} from "@/api/practice";

import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import musicSet from "./modal/set.vue";
import folderSele from "./modal/folder.vue";
import labelSele from "./modal/label.vue";
import wrongSele from "./modal/wrong.vue";
import simulationSele from "./modal/simulation.vue";
import selfSele from "./modal/self.vue";
export default {
  components: {
    musicSet,
    folderSele,
    labelSele,
    wrongSele,
    simulationSele,
    selfSele,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const state = reactive({
      configData: {},
      labelData: [],
      isHaveQuestion: false,
    });
    const folderSeleRef = ref(null);
    const labelSeleRef = ref(null);
    const wrongSeleRef = ref(null);
    const simulationSeleRef = ref(null);
    const selfSeleRef = ref(null);

    practiceConfig().then((res) => {
      state.configData = res.data;
      state.configData.folderTree = state.configData.folderTree || [];
      state.isHaveQuestion = res.data.isHaveQuestion == 1;
    });

    interestLabels().then((res) => {
      state.labelData = res.data || [];
    });

    const getHistory = (type, typeNum) => {
      if (!state.isHaveQuestion) {
        proxy.$message.warning(proxy.$t("practice.no_test_questions"));
        return false;
      }
      practiceHistory({ type: typeNum }).then((res) => {
        if (res.data) {
          Modal.confirm({
            title: proxy.$t(
              "practice.do_you_want_to_continue_answering_question"
            ),
            icon: createVNode(ExclamationCircleOutlined),
            okText: proxy.$t("cm_yes"),
            onOk() {
              switch (type) {
                case "daily":
                  router.push({
                    path: "/mine/practice/detail",
                    query: {
                      type: 1,
                      isHistory: 1,
                    },
                  });
                  break;
                case "interest":
                  router.push({
                    path: "/mine/practice/detail",
                    query: {
                      type: 2,
                      isHistory: 1,
                    },
                  });
                  break;
                case "simulation":
                  router.push({
                    path: "/mine/practice/paper",
                    query: {
                      type: 3,
                      isHistory: 1,
                    },
                  });
                  break;
                case "self":
                  router.push({
                    path: "/mine/practice/paper",
                    query: {
                      type: 4,
                      isHistory: 1,
                    },
                  });
                  break;
              }
            },
            cancelText: proxy.$t("cm_no"),
            onCancel() {
              jump(type);
            },
          });
        } else {
          jump(type);
        }
      });
    };

    const jump = (type) => {
      switch (type) {
        case "record":
          router.push({
            path: "/mine/practice/record",
          });
          break;
        case "daily":
          if (state.configData.everydayQuestionRule == 1) {
            folderSeleRef.value.open(1, state.configData.folderTree);
          } else {
            ls.set("newPractice", true);
            router.push({
              path: "/mine/practice/detail",
              query: {
                type: 1,
              },
            });
          }
          break;
        case "interest":
          if (state.configData.interestQuestionRule == 1) {
            folderSeleRef.value.open(2, state.configData.folderTree);
          } else {
            labelSeleRef.value.open(2, state.labelData);
          }
          break;
        case "wrong":
          wrongSeleRef.value.open();
          break;
        case "simulation":
          simulationSeleRef.value.open(
            state.configData.practiceQuestionRule == 1,
            state.configData.folderTree
          );
          break;
        case "self":
          selfSeleRef.value.open(
            state.configData.practiceQuestionRule == 1,
            state.configData.folderTree
          );
          break;
      }
    };

    return {
      ...toRefs(state),
      folderSeleRef,
      labelSeleRef,
      wrongSeleRef,
      simulationSeleRef,
      selfSeleRef,
      getHistory,
      jump,
    };
  },
};
</script>

<style lang="less" scoped>
.component-wrap {
  min-height: 100%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
}
.banner {
  width: 100%;
  height: 200px;
  background-image: url("~@/assets/image/practice/banner.png");
  background-size: 100% 100%;
  border-radius: 8px 8px 0 0;
}
.header {
  margin: 0 24px;
  padding: 24px 0;
  line-height: 26px;
  .mixinFlex(space-between; center);
  border-bottom: 1px solid #f4f4f4;
  h3 {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin: 0;
  }
  & > div {
    .mixinFlex(flex-end; center);
    span {
      font-size: 16px;
      color: @color-theme;
      margin-left: 24px;
      cursor: pointer;
    }
  }
}
.cards {
  margin: 0 24px;
  padding-top: 24px;
  .row {
    margin-bottom: 20px;
    .mixinFlex(space-between; center);
    .card {
      padding: 16px;
      background-size: 100% 100%;
      user-select: none;
      cursor: pointer;
      h5 {
        font-size: 16px;
        font-weight: 600;
        color: inherit;
        margin-bottom: 12px;
      }
      h6 {
        font-size: 14px;
        font-weight: 400;
        color: inherit;
      }
      &.daily {
        width: 289px;
        height: 188px;
        background-image: url("~@/assets/image/practice/daily.png");
        color: #3d6c88;
      }
      &.interest {
        width: 289px;
        height: 188px;
        background-image: url("~@/assets/image/practice/interest.png");
        color: #d96834;
      }
      &.wrong {
        width: 289px;
        height: 188px;
        background-image: url("~@/assets/image/practice/wrong.png");
        color: #629483;
      }
      &.simulation {
        width: 443px;
        height: 188px;
        background-image: url("~@/assets/image/practice/simulation.png");
        color: #db4883;
      }
      &.self {
        width: 443px;
        height: 188px;
        background-image: url("~@/assets/image/practice/self.png");
        color: #5774e6;
      }
    }
  }
}
</style>
