<template>
  <a-modal
    :title="$t('practice.sound_effects')"
    :width="400"
    :visible="visible"
    @ok="save"
    @cancel="close"
  >
    <div class="set">
      <div class="label">{{ $t("practice.sound_effects_tip") }}</div>
      <a-switch v-model:checked="hasMusic" />
    </div>
  </a-modal>
</template>
<script>
import { reactive, toRefs } from "vue";
import { practiceConfig, soundSave } from "@/api/practice";

export default {
  setup() {
    const state = reactive({
      visible: false,
      hasMusic: false,
    });

    const open = () => {
      state.visible = true;
      practiceConfig().then((res) => {
        state.hasMusic = res.data.wrongSoundPc == 1;
      });
    };

    const close = () => {
      state.visible = false;
    };

    const save = () => {
      soundSave({
        platform: 1,
        wrongSound: state.hasMusic ? 1 : 0,
      });
      close();
    };

    return {
      ...toRefs(state),
      open,
      close,
      save,
    };
  },
};
</script>
<style lang="less" scoped>
.set {
  .mixinFlex(space-between; center);
  .label {
    width: 290px;
  }
}
</style>
