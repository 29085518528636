<template>
  <a-modal
    title=""
    :width="652"
    :bodyStyle="{ padding: 0 }"
    :visible="visible"
    :footer="null"
    @cancel="close"
  >
    <div class="folder">
      <div class="banner">
        <p class="p1">{{ $t("practice.select_tags") }}</p>
        <p class="p2">{{ $t("practice.can_select_multiple") }}</p>
      </div>
      <div class="list" v-if="labels.length">
        <a-checkbox-group v-model:value="checkedKeys">
          <a-checkbox
            class="item-checkbox"
            :value="item.labelName"
            v-for="(item, index) in labels"
            :key="index"
          >
            <div>{{ item.labelName }}（{{ item.nums }}）</div>
          </a-checkbox>
        </a-checkbox-group>
      </div>
      <a-empty
        v-else
        :image="require('@/assets/image/no_data_3.png')"
        :image-style="{ height: '106px' }"
        style="padding: 20px 0 30px"
      >
        <template #description>
          <span style="color: #999">{{
            $t("practice.no_labels")
          }}</span>
        </template>
      </a-empty>
      <div class="btn">
        <a-button
          type="primary"
          @click="jump()"
          :disabled="!checkedKeys.length"
        >
          {{ $t("practice.enter_quiz") }}
        </a-button>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import ls from "@/utils/local-storage";
export default {
  setup() {
    const router = useRouter();
    const state = reactive({
      visible: false,
      type: 0,
      labels: [],
      checkedKeys: [],
    });

    const open = (type, data) => {
      state.type = type;
      state.labels = data;
      state.checkedKeys = [];
      state.visible = true;
    };

    const close = () => {
      state.visible = false;
    };

    const jump = () => {
      close();
      ls.set("newPractice", true);
      router.push({
        path: "/mine/practice/detail",
        query: {
          type: 2,
          labelIds: state.checkedKeys.join(),
        },
      });
    };

    return {
      ...toRefs(state),
      open,
      close,
      jump,
    };
  },
};
</script>
<style lang="less" scoped>
.folder {
  .banner {
    height: 228px;
    background-image: url("~@/assets/image/practice/select-banner.png");
    background-size: 100% 100%;
    line-height: 1.2;
    padding: 74px 0 0 48px;
    .p1 {
      color: #bb96fb;
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 12px;
    }
    .p2 {
      color: #5ccb78;
      font-size: 20px;
      margin: 0;
    }
  }
  .list {
    padding: 0 24px;
    margin-top: 24px;
    min-height: 100px;
    .ant-checkbox-group {
      width: 100%;
      .mixinFlex(space-between);
      flex-wrap: wrap;
    }
    .item-checkbox {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 16px;
      border-radius: 4px;
      background-color: #fafcfe;
      border: 1px solid #ebebeb;
      padding: 8px 12px;
      .mixinFlex(flex-start; center);
      white-space: normal !important;
      width: 49%;
      margin-right: 0;
      ::v-deep(.ant-checkbox) {
        display: none;
        width: 0px;
        height: 0px;
        overflow: hidden;
      }
      ::v-deep(.ant-checkbox + span) {
        padding-right: 0;
        padding-left: 0;
        width: 100%;
        height: 100%;
      }
      &.ant-checkbox-wrapper-checked {
        border-color: @color-theme;
        color: @color-theme;
        background-color: @color-ligh-theme;
      }
    }
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
  .btn {
    text-align: center;
    padding: 24px 0 32px;
  }
}
</style>
