<template>
  <a-modal
    title=""
    :width="652"
    :bodyStyle="{ padding: 0 }"
    :visible="visible"
    :footer="null"
    @cancel="close"
  >
    <div class="folder">
      <div class="banner">
        <p class="p1">{{ $t("practice.select_question_bank_directory") }}</p>
        <p class="p2">{{ $t("practice.can_select_multiple") }}</p>
      </div>
      <div class="search">
        <a-input-search
          v-model:value="keyword"
          style="width: 390px"
          :placeholder="
            $t('practice.please_enter_name_of_question_bank_to_filter')
          "
          @change="inputChange"
        />
      </div>
      <div class="tree">
        <a-tree
          checkable
          multiple
          :selectable="false"
          :tree-data="folders"
          :replace-fields="replaceFields"
          v-model:checkedKeys="checkedKeys"
        >
          <template #title="item">
            {{ item.name }}（{{ item.questionNum }}）
          </template>
        </a-tree>
        <a-empty
          v-if="!folders.length"
          :image="require('@/assets/image/no_data_3.png')"
          :image-style="{ height: '106px' }"
          style="padding: 10px 0"
        >
          <template #description>
            <span style="color: #999">{{ $t("practice.no_folders") }}</span>
          </template>
        </a-empty>
      </div>
      <div class="btn">
        <a-button
          type="primary"
          @click="jump()"
          :disabled="!checkedKeys.length"
        >
          {{ $t("practice.enter_quiz") }}
        </a-button>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import ls from "@/utils/local-storage";
import { debounce } from "lodash";
import { practiceConfig } from "@/api/practice";
export default {
  setup() {
    const router = useRouter();
    const state = reactive({
      visible: false,
      type: 0,
      folders: [],
      replaceFields: {
        children: "list",
        title: "name",
        key: "id",
        value: "id",
      },
      keyword: "",
      checkedKeys: [],
    });

    const open = (type, data) => {
      state.type = type;
      state.folders = JSON.parse(JSON.stringify(data));
      state.keyword = "";
      state.checkedKeys = [];
      state.visible = true;
    };

    const close = () => {
      state.visible = false;
    };

    const inputChange = debounce(() => {
      practiceConfig({
        keyword: state.keyword,
      }).then((res) => {
        state.folders = res.data.folderTree || [];
      });
    }, 500);

    const jump = () => {
      close();
      ls.set("newPractice", true);
      router.push({
        path: "/mine/practice/detail",
        query: {
          type: state.type,
          folderIds: state.checkedKeys.join(),
        },
      });
    };

    return {
      ...toRefs(state),
      open,
      close,
      inputChange,
      jump,
    };
  },
};
</script>
<style lang="less" scoped>
.folder {
  .banner {
    height: 228px;
    background-image: url("~@/assets/image/practice/select-banner.png");
    background-size: 100% 100%;
    line-height: 1.2;
    padding: 74px 0 0 48px;
    .p1 {
      color: #bb96fb;
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 12px;
    }
    .p2 {
      color: #5ccb78;
      font-size: 20px;
      margin: 0;
    }
  }
  .search {
    padding: 0 24px;
    margin-top: 24px;
  }
  .tree {
    padding: 0 24px 0 18px;
    margin-top: 8px;
    min-height: 100px;
  }
  .btn {
    text-align: center;
    padding: 24px 0 32px;
  }
}
</style>
